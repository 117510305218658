import React, { useState, useEffect } from "react";
import './footer.css';

function AppFooter() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <section>
      {/* Your main content goes here */}
      
      {/* Footer */}
      <footer className="footer max-w-screen-xl">
          <div className="footer-center">
            <div className="footer-center-top">
              <h2>Questions? - Let's chat!</h2>
            </div>
            <div className="footer-left-bottom">
                <a href="/en/contact" className="button-link">
                  Contact Us →
                </a>
              </div>
            <div className="footer-center-bottom">
              <p>
                <a href="/en/impression">Impression</a> | <a href="/en/privacypolicy">Privacy Policy</a> | ©&nbsp;CONTRA&nbsp;2024
              </p>
            </div>
          </div>
      </footer>
      {showTopBtn && (
        <div className="go-top" onClick={goTop}></div>
      )}
    </section>
  );
}

export default AppFooter;
