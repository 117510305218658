import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css';
import AppHerode from '../de_hero';
import AppFooterde from '../de_footer';
import Appheaderde from '../de_header';
import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const teamsData = [
  {
    id: 1,
    imgSrc: require("../../assets/images/routegif.gif"),
    link: "/en/casestudy1",
    title: "Trassenplanung",
    imgAlt: "Trassenplanung",
    description: "Unsere leistungsstarke Software, CONTRA, übernimmt die präzise Berechnung und Planung neuer Trassen zwischen zwei Standorten. Mithilfe verschiedener, individuell anpassbarer Kriterien optimieren wir die Routen, um Ihnen globale Spitzenqualität zu bieten. Außerdem können wir mit CONTRA auch eine optimale Route innerhalb Ihres vorgegebenen Budgets erstellen. Erleben Sie die Zukunft der Trassenplanung.",
    mobileDescription: "Unsere leistungsstarke Software, CONTRA, übernimmt die präzise Berechnung und Planung neuer Trassen zwischen zwei Standorten. Mithilfe verschiedener, individuell anpassbarer Kriterien optimieren wir die Routen, um Ihnen globale Spitzenqualität zu bieten. Außerdem können wir mit CONTRA auch eine optimale Route innerhalb Ihres vorgegebenen Budgets erstellen. Erleben Sie die Zukunft der Trassenplanung.",
  },
  {
    id: 2,
    imgSrc: require("../../assets/images/ezgif.com-gif-maker.gif"),
    link: "/en/casestudy1",
    title: "Glasfasernetzwerk (FiONA)",
    imgAlt: "Glasfasernetzwerk (FiONA)",
    description: "Bei der Planung von Glasfasernetzen nutzt CONTRA GIS-Daten, Haushaltskoordinaten und bodenbezogene Verlegungskosten. Unsere Software wählt dann eine Zielfunktion, sei es die Minimierung der Baukosten oder die Verkleinerung der Netzgröße. Dank der Automatisierung der Datenauswertung generieren wir ein optimales Netzwerk, in dem entweder die Verlegungskosten oder die Netzwerklänge zwischen den Gebäuden minimiert werden.",
    mobileDescription: "Bei der Planung von Glasfasernetzen nutzt CONTRA GIS-Daten, Haushalts-koordinaten und bodenbezogene Ver-legungskosten. Unsere Software wählt dann eine Zielfunktion, sei es die Minimierung der Baukosten oder die Verkleinerung der Netzgröße. Dank der Automatisierung der Datenauswertung generieren wir ein optimales Netzwerk, in dem entweder die Verlegungskosten oder die Netzwerklänge zwischen den Gebäuden minimiert werden.",
  },
  {
    id: 3,
    imgSrc: require("../../assets/images/telecom.gif"),
    link: "/en/casestudy1",
    imgAlt: "5G-Funkmasten",
    title: "5G-Funkmasten",
    description: "Die intelligenten Algorithmen von CONTRA übernehmen die optimale und strategisch wertvolle Platzierung von Antennen, die für eine umfassende Mobilfunknetzabdeckung von entscheidender Bedeutung sind. Dadurch werden die Kosten nachhaltig und naturschonend reduziert und weniger Funkmasten errichtet. Unsere Software analysiert Gebiete Punkt für Punkt, bestimmt die ideale Antennenverteilung und wählt die effizienteste Lösung für eine umfassende Funkabdeckung aus.",
    mobileDescription: "Die intelligenten Algorithmen von CONTRA übernehmen die optimale und strategisch wertvolle Platzierung von Antennen, die für eine umfassende Mobilfunknetzabdeckung von entscheidender Bedeutung sind. Dadurch werden die Kosten nachhaltig und naturschonend reduziert und weniger Funkmasten errichtet. Unsere Software analysiert Gebiete Punkt für Punkt, bestimmt die ideale Antennenverteilung und wählt die effizienteste Lösung für eine umfassende Funkabdeckung aus.",
  },
  
  
  
]

function Apphomede() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <header id="header">
        <Appheaderde />
      </header>
      <main>
        <AppHerode />
        
        {/* Video Section */}
        <div className="video-container mt-6 flex justify-center">
          <video className="w-full max-w-[1300px] h-auto" autoPlay muted loop controls>
            <source src={require("../../../src/assets/images/seeroute_deutsch_2k.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>


        {/* Content Section */}
        <div className='content-container'>
          <Row>
            {teamsData.map((teams) => {
              return (
                <Col sm={12} md={6} lg={4} key={teams.id}>
                  <div className="mb-6">
                    <div className="bg-white p-2 rounded-lg shadow-md">
                      <div className="image">
                        <Image src={teams.imgSrc} alt={teams.imgAlt} />
                        <div className="hover">
                          <ul>
                            <li>
                              <a href="/en/team"></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pt-4 pr-4 pl-4 pb-2 bg-blue-100 rounded-lg ">
                        <div className="content">
                          <h3 className="text-xl font-semibold mb-2">
                            {teams.title}
                          </h3>
                          <p className="text-base font-normal text-justify text-gray-700">
                            {isMobile ? teams.mobileDescription : teams.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <AppFooterde />
      </main>
    </div>
  );
}

export default Apphomede;


