import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css';
import AppHero from '../hero';
import AppFooter from '../footer';
import Appheader from '../header';
import React from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './homepage.css';

// Teams data
const teamsData = [
  {
    id: 1,
    imgSrc: require("../../assets/images/routegif.gif"),
    link: "/en/casestudy1",
    title: "Road Planning",
    imgAlt: "Optimal road Planning",
    description: "Our software CONTRA calculates and plans the construction of new routes between two locations. These routes are optimized based on different criteria or a combination of them. The calculated routes are globally optimal, surpassing conventional methods in terms of quality. The system is also able to calculate the optimal route for a given budget.",
  },
  {
    id: 2,
    imgSrc: require("../../assets/images/ezgif.com-gif-maker.gif"),
    link: "/en/casestudy1",
    title: "Fiber Optic Network (FiONA)",
    imgAlt: "Road panning",
    description: "In the planning of fiber optic networks, CONTRA utilizes GIS data, household coordinates, and ground condition-based laying costs. It then selects an objective function, such as minimizing construction costs or network size. The software automates data evaluation to determine an optimal network, minimizing either laying costs or network length between households.",
  },
  {
    id: 3,
    imgSrc: require("../../assets/images/telecom.gif"),
    link: "/en/casestudy1",
    imgAlt: "Road panning",
    title: "5G Antenna Placement",
    description: "CONTRA's intelligent algorithms optimize the placement of telecommunication antennas, crucial for comprehensive cellular network coverage. It minimizes costs by strategically positioning antennas of various power and signal coverage, like 5G, LTE, or GSM/2G. It analyzes areas point by point, determining the ideal antenna distribution and selecting the fewest antennas for extensive coverage.",
  },
]

function Apphome() {
  return (
    <div className="App">
      <header id="header">
        <Appheader />
      </header>
      <main>
        <AppHero />

        {/* Video Section */}
        <div className="video-container mt-6 flex justify-center">
          <video className="w-full max-w-[1300px] h-auto" autoPlay muted loop controls>
            <source src={require("../../../src/assets/images/seeroute_english_2k.mp4")} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>


        {/* Content Section */}
        <div className='content-container'>
          <Row>
            {teamsData.map((teams) => {
              return (
                <Col sm={12} md={6} lg={4} key={teams.id}>
                  <div className="mb-6">
                    <div className="bg-white p-2 rounded-lg shadow-md">
                      <div className="image">
                        <Image src={teams.imgSrc} alt={teams.imgAlt} />
                        <div className="hover">
                          <ul>
                            <li>
                              <a href="/en/team"></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pt-4 pr-4 pl-4 pb-2 bg-blue-100 rounded-lg ">
                        <div className="content">
                          <h3 className="text-xl font-semibold mb-2">
                            {teams.title}
                          </h3>
                          <p className="text-lg font-normal text-justify text-gray-700">
                            {teams.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>

        <AppFooter />
      </main>
    </div>
  );
}

export default Apphome;
